<style>
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button,
input::-webkit-calendar-picker-indicator {
  appearance: none;
  display: none;
  margin: 0;
}

.text-input__container {
  background: white;
  color: rgba(0, 0, 0, 0.5);
  display: inline-flex;
  font: normal 16px var(--sans-font);
}

.text-input__container--block {
  display: flex;
}

.text-input__container--round {
  --text-input-radius: 5px;
  border-radius: var(--text-input-radius);
}

.text-input__container--round > :first-child {
  border-top-left-radius: var(--text-input-radius);
  border-bottom-left-radius: var(--text-input-radius);
}

.text-input__container--round > :last-child {
  border-top-right-radius: var(--text-input-radius);
  border-bottom-right-radius: var(--text-input-radius);
}

.text-input__container--disabled {
  background: #f6f6f6;
}

.text-input__container > * {
  border: 1px solid #d6d6d6;
}

.text-input__container--large-polls > * {
  border: 1px solid #808080;
  border-radius: 4px;
}

.text-input__container--primary > * {
  border-color: var(--highlight);
}

.text-input__container > :not(:first-child) {
  margin-left: -4px;
}

.text-input__wrapper {
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  border-radius: 4px;
}

.text-input__wrapper > * {
  padding: 0.5em;
}

.text-input__container--large > .text-input__wrapper {
  align-items: baseline;
  display: flex;
  flex-grow: 1;
}

.text-input__container--large > .text-input__wrapper > * {
  padding: 1em 0;
}

.text-input__container--large-polls > .text-input__wrapper > :last-child,
.text-input__container--large > .text-input__wrapper > :first-child {
  padding-left: 1em;
}

.text-input__container--large-polls > .text-input__wrapper > :last-child,
.text-input__container--large > .text-input__wrapper > :last-child {
  padding-left: 1em;
  padding-right: 1em;
  font-weight: 400;
  color: #555555;
  font-size: 16px;
}

.text-input {
  background: transparent;
  border: 0;
  color: black;
  flex-grow: 1;
  font: inherit;
  width: auto;
}

.text-input[data-single-line] {
  height: 1.2em; /* Keep `type="date"` in alignment. */
}

.text-input--block {
  width: 100%;
}

.text-input:focus {
  outline: none !important;
}

.text-input__outside-content {
  background: #f6f6f6;
  padding: 0.5em;
  position: relative;
}

.text-input__container--primary > .text-input__outside-content {
  background: var(--highlight);
  color: white;
}

.text-input--invalid {
  border: 1px solid #ff4814;
}

::-moz-placeholder {
  color: #bbb;
}

:-ms-input-placeholder {
  color: #bbb;
}

::-webkit-input-placeholder {
  color: #bbb;
}
</style>

<template>
  <span class="text-input__container" :class="containerClassName">
    <div
      v-if="$slots.before"
      class="text-input__outside-content"
      :style="$root.isMobile ? 'font-size:14px;padding-left:.3em;padding-right:.3em;padding-top:10px;' : ''"
    >
      <slot name="before" />
    </div>

    <div class="text-input__wrapper">
      <div v-if="$slots.prefix" class="text-input__inside-content">
        <slot name="prefix" />
      </div>

      <textarea v-if="multiline" :rows="rows" v-bind="inputBindings" v-on="inputEventHandlers" ref="multiline"></textarea>
      <masked-input v-else-if="mask" :mask="mask" v-bind="inputBindings" v-on="inputEventHandlers" ref="mask"></masked-input>
      <input v-else :type="type" v-bind="inputBindings" v-on="inputEventHandlers" ref="text" />

      <div v-if="$slots.suffix" class="text-input__inside-content">
        <slot name="suffix" />
      </div>
    </div>

    <div v-if="$slots.after" class="text-input__outside-content">
      <slot name="after" />
    </div>
  </span>
</template>

<script>
import MaskedInput from 'vue-masked-input';

export default {
  components: {
    MaskedInput,
  },

  inheritAttrs: false,

  props: {
    id: String,
    value: {},
    multiline: Boolean,
    block: Boolean,
    round: Boolean,
    primary: Boolean,
    large: Boolean,
    invalid: Boolean,
    mask: {}, // See https://github.com/niksmr/vue-masked-input
    filter: { type: RegExp, default: null },
    disabled: Boolean,
    rows: {
      type: Number,
      default: 4,
    },
    type: {
      type: String,
      default: 'text',
    },
    autofocus: Boolean,
    polls: { type: Boolean, default: false },
  },

  data() {
    return {
      inputName: this.$attrs.name,
    };
  },

  computed: {
    containerClassName() {
      return {
        'text-input__container--block': this.block,
        'text-input__container--primary': this.primary,
        'text-input__container--round': this.round,
        'text-input__container--large': this.large && !this.polls,
        'text-input__container--large-polls': this.polls,
        'text-input__container--disabled': this.disabled,
      };
    },

    inputClassName() {
      return {
        'text-input': true,
        'text-input--block': this.block,
        'text-input--invalid': this.invalid,
      };
    },

    inputBindings() {
      return {
        ...this.$attrs,
        id: this.id,
        name: this.inputName,
        class: this.inputClassName,
        'data-single-line': !this.multiline,
        disabled: this.disabled,
        value: this.value,
      };
    },

    inputEventHandlers() {
      return {
        focus: this.handleFocus,
        input: this.passThrough,
        change: this.passThrough,
      };
    },
  },

  methods: {
    setInputName() {
      // Since Vue usually handles input values, we usually don't care about input `name`s,
      // but browser-autofilled inputs without names don't always fire "input" events, so Vue can't pick up their initial value.
      // As a workaround, we'll make a name up, ideally based on an associated label.

      if (this.$attrs.name) {
        return this.$attrs.name;
      }

      let label;

      if (this.$attrs.ariaLabeledby) {
        const firstLabelId = this.$attrs.ariaLabeledby.split(',')[0].trim();
        label = document.getElementById(firstLabelId);
      }

      if (!label && this.id) {
        label = document.querySelector(`label[for="${this.id}"]`);
      }

      if (!label) {
        label = this.$el.closest('label');
      }

      if (!label) {
        label = {
          innerText: `_unnamed-input-${Math.random()}`,
        };
      }

      const firstLineOfLabel = label.innerText.trim().split('\n')[0].trim();
      this.inputName = firstLineOfLabel.replace(/\W+/g, '_').toLowerCase();
    },

    async handleFocus(event) {
      const readOnly = 'readonly' in this.$attrs || 'read-only' in this.$attrs;
      if (readOnly) {
        await this.$nextTick();
        event.target.setSelectionRange(0, event.target.value.length);
      }
    },

    filterValue(event) {
      if (this.mask) {
        // Pass up the result straight from the masked-input component.
        return event;
      } else if (this.filter) {
        // Store the selection state to restore after manually setting to value.
        const { selectionStart, selectionEnd, selectionDirection } = event.target;

        const matches = event.target.value.match(this.filter);
        const filteredValue = matches ? matches.join('') : this.value;

        // Replace the typed-in value with the filtered one.
        // Note this function is _not_ async.
        this.$nextTick(() => {
          event.target.value = filteredValue;
          event.target.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
        });

        return filteredValue;
      } else {
        return event.target.value;
      }
    },

    // `v-model` expects custom components to emit a value rather than emit a native event.
    passThrough(event) {
      this.$emit(event.type, this.filterValue(event));
    },

    focus() {
      if (this.multiline) {
        this.$refs.multiline.focus();
      } else if (this.mask) {
        this.$refs.mask.focus();
      } else {
        this.$refs.text.focus();
      }
    },
  },

  mounted() {
    if (this.filter && this.type !== 'text') {
      console.warn('CpTextInput’s `filter` prop only works reliably when `type="text"`.', this);
    }

    this.$el.removeAttribute('tabindex'); // Prevent container focus.

    this.setInputName();
    if (this.autofocus) {
      this.focus();
    }
  },

  watch: {
    async mask() {
      // TODO: Selecting this input by tag name is fragile.
      // Make a direct reference that takes into account that it might be a Vue component.
      const input = this.$el.querySelector('input');
      const { selectionStart, selectionEnd, selectionDirection } = input;
      await this.$nextTick();
      setTimeout(() => {
        input.focus();
        input.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
      }, 100); // TODO: get rid of this!
    },
  },
};
</script>
