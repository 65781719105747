<script>
export default {
  name: 'CpShowImageInCard',
  props: {
    image: { type: String },
    link: { type: String, default: null },
    altMessage: { type: String, default: 'Post Image' },
    fullWidth: { type: Boolean, default: false },
  },
  data() {
    return {
      isRectangular: false,
    };
  },
  methods: {
    checkAspectRatio(event) {
      const { naturalWidth, naturalHeight } = event.target;
      this.isRectangular = naturalWidth > naturalHeight;
    },
  },
};
</script>
<template>
  <a v-if="!!image" :href="link" class="img-container">
    <img
      :src="image"
      :alt="altMessage"
      width="100%"
      @load="checkAspectRatio"
      loading="lazy"
      :class="{ rectangle: isRectangular, 'not-rectangle': !isRectangular, fullWidth: fullWidth }"
    />
  </a>
</template>

<style scoped>
.img-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.img-container img {
  aspect-ratio: auto;
}
.img-container img.not-rectangle {
  max-width: 50%;
}

.img-container img.rectangle {
  max-width: 75%;
}

.img-container img.fullWidth {
  max-width: 100%;
}
</style>
