export const SectionPostTypes = Object.freeze({
    PETITIONS: 'petitions',
    CROWDPAC: 'crowdpac',
    NEWS: 'news',
    BLOGS: 'blogs',
    LOBBY: 'lobby',
    OPINION: 'opinion',
    POLLS: 'polls',
    POST_SHARED: 'post_shared',
    MEMBER: 'member',
    GROUPS: 'groups',
    GROUP_OPINIONS: 'group-opinions',
    EVENTS: 'events',
    ELECTIONS: 'races',
    CAMPAIGN_UPDATE: 'campaignUpdate',
    CANDIDATE: 'candidate',
    LIVESTREAMS: 'livestreams',
    RALLIES: 'rallies',
    CUSTOM_CONTENTS: 'custom-contents',
});
