<style scoped>
.toasted {
    background: var(--light-gray);
    box-sizing: border-box;
    color: var(--color);
    overflow: hidden;
    min-height: 55px;
    padding: 0 var(--gutter);
    width: 100%;
    text-align: center;
    display: flex;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
}
@media only screen and (min-width: 768px) {
    .toasted {
        min-height: 70px;
    }
}
.toasted--success {
    background: var(--success);
    border: 1px solid var(--success-border);
    color: white;
}
.toasted--danger {
    background: var(--highlight);
    border: 1px solid var(--highlight-border);
    color: white;
}
.toasted-bounce-enter-active {
    animation: toasted-bounce 0.25s ease;
}
.toasted-bounce-leave-active {
    animation: toasted-bounce 0.25s ease reverse;
}
@keyframes toasted-bounce {
    0% {
        transform: scale(3, 0);
        opacity: 0;
    }
    100% {
        transform: scale(1, 1);
        opacity: 1;
    }
}
</style>
<template>
    <transition name="toasted-bounce">
        <div v-if="show" @click.prevent="bye" :class="className">
            <template v-if="!!message">{{ message }}</template>
            <slot v-else></slot>
        </div>
    </transition>
</template>
<script>
export default {
    props: {
        message: String,
        duration: {
            type: Number,
            default: 2000
        },
        theme: {
            type: String,
            default: 'info'
        },
        success: Boolean,
        info: Boolean,
        danger: Boolean
    },
    data() {
        return {
            show: false
        };
    },
    computed: {
        classTheme() {
            let theme = this.theme;
            if (this.success) {
                theme = 'success';
            }
            if (this.info) {
                theme = 'info';
            }
            if (this.danger) {
                theme = 'danger';
            }

            return 'toasted--' + theme;
        },
        className() {
            let classes = { toasted: true };
            classes[this.classTheme] = true;
            return classes;
        }
    },
    methods: {
        bye() {
            this.show = false;
            this.$emit('clear');
        }
    },
    mounted() {
        this.show = true;
        setTimeout(this.bye, this.duration);
    },
};
</script>
